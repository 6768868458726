import React from "react";
import TradeFiOverviewCard from "../../../components/Card/tradeFiOverviewCard";

const TradeFiSummary = ({ data }) => {
	return (
		<div className="container">
			<div className="row d-flex justify-content-between">
				<div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap mb-3">
					<TradeFiOverviewCard
						name="Total Loans Disbursed"
						value={
							data?.total_loan_disbursed_amount
								? `$${new Intl.NumberFormat().format(
										data?.total_loan_disbursed_amount,
								  )}`
								: "$0.00"
						}
						// value2={data?.loans_disbursed_percentage || "0"}
						variant="main"
					/>
				</div>
				<div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap mb-3">
					<TradeFiOverviewCard
						name="Active Loans"
						value={
							data?.total_approved_loan_amount
								? `$${new Intl.NumberFormat().format(
										data?.total_approved_loan_amount,
								  )}`
								: "$0.00"
						}
						// value2={data?.active_loans_percentage || "0"}
						variant="main"
					/>
				</div>
				<div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap mb-3">
					<TradeFiOverviewCard
						name="Average Interest Rate"
						value={
							data?.average_interest_rate
								? `${data?.average_interest_rate}%`
								: "0%"
						}
						// value2={data?.total_loss_percentage || "0"}
						variant="main"
					/>
				</div>
				<div className="col-12 col-sm-6 col-md-3 d-flex flex-wrap mb-3">
					<TradeFiOverviewCard
						name="Total Loans Repaid"
						value={
							data?.total_loans_repaid_amount
								? `$${new Intl.NumberFormat().format(data?.total_loans_repaid_amount)}`
								: "$0"
						}
						// value2={data?.total_loans_percentage || "0"}
						variant="main"
					/>
				</div>
			</div>
		</div>
	);
};

export default TradeFiSummary;
