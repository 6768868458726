import React from "react";
import "./tradeFiPoolDetail.scss";
import { formatRisk } from "../../../utils/projectUtils";

const InfoRow = ({ title, value, isFirst }) => (
	<div className={`row ${isFirst ? "first-row" : ""} border-bottom`}>
		<div className="col-md-2 my-4">
			<div className="mb-0 title-text text-muted">{title}</div>
		</div>
		<div className="col-md-10 value-text my-4">{value || "N/A"}</div>
	</div>
);

const BasicInfo = ({
	description,
	totalValue,
	apy,
	riskRating,
	liquidity,
	countryRating,
	totalLoan,
}) => {
	return (
		<div className="row pt-4">
			<InfoRow title="Description" value={description} isFirst={true} />
			<InfoRow title="Total Pool Value" value={totalValue} />
			<InfoRow title="Active Loan Value" value={totalLoan} />
			<InfoRow title="APY" value={apy} />
			<InfoRow title="Risk Rating" value={formatRisk(riskRating)} />
			<InfoRow title="Country Rating" value={countryRating} />
			{/* <InfoRow title="Liquidity" value={liquidity} /> */}
		</div>
	);
};

export default BasicInfo;
